/**
* Generated theme by Material Theme Generator
* https://materialtheme.arcsine.dev
* Fork at: https://materialtheme.arcsine.dev/?c=YHBhbGV0dGU$YHByaW1hcnk$YF48Izk1YjRiNSIsIj9lcjwjZGZlOWU5IiwiO2VyPCM3ODljOWR$LCIlPmBePCNlOWU0ZGMiLCI~ZXI8I2Y4ZjdmNSIsIjtlcjwjZGZkOWNlfiwid2Fybj5gXjwjZmYwMDAwIiwiP2VyPCNmZmIzYjMiLCI7ZXI8I2ZmMDAwMH4sIj9UZXh0PCM1ZTVlNWUiLCI~PTwjZTllNWU1IiwiO1RleHQ8IzVlNWU1ZSIsIjs9PCNiY2M2YzF$LCJmb250cz5bYEA8KC00fixgQDwoLTN$LGBAPCgtMn4sYEA8KC0xfixgQDxoZWFkbGluZX4sYEA8dGl0bGV$LGBAPHN1YiktMn4sYEA8c3ViKS0xfixgQDxib2R5LTJ$LGBAPGJvZHktMX4sYEA8YnV0dG9ufixgQDxjYXB0aW9ufixgQDxpbnB1dCIsInNpemU$bnVsbH1dLCJpY29uczxPdXRsaW5lZCIsIj9uZXNzPnRydWUsInZlcnNpb24$MTJ9
*/

@use '@angular/material'as mat;

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.

// Fonts
@import 'https://fonts.googleapis.com/icon?family=Material+Icons+Outlined';
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500&display=swap');

@font-face {
    font-family: 'CormorantGaramond';
    src: url('../assets/fonts/CormorantGaramond-Bold.ttf');
    font-weight: 800;
}

@font-face {
    font-family: 'HalyardDisplayBook';
    src: url('../assets/fonts/Halyard-Display-Book.ttf');
    font-weight: 400;
}

@font-face {
    font-family: 'Brother1816';
    src: url('../assets/fonts/Brother1816-Regular.ttf');
    font-weight: 400;
}

$fontConfig: (display-4: mat.define-typography-level(112px, 112px, 300, 'CormorantGaramond', -0.0134em),
    display-3: mat.define-typography-level(56px, 56px, 400, 'CormorantGaramond', -0.0089em),
    display-2: mat.define-typography-level(45px, 48px, 400, 'CormorantGaramond', 0.0000em),
    display-1: mat.define-typography-level(34px, 40px, 400, 'CormorantGaramond', 0.0074em),
    headline: mat.define-typography-level(24px, 32px, 400, 'CormorantGaramond', 0.0000em),
    title: mat.define-typography-level(20px, 32px, 500, 'CormorantGaramond', 0.0075em),
    subheading-2: mat.define-typography-level(16px, 28px, 400, 'Brother1816', 0.0094em),
    subheading-1: mat.define-typography-level(15px, 24px, 500, 'CormorantGaramond', 0.0067em),
    body-2: mat.define-typography-level(14px, 24px, 500, 'HalyardDisplayBook', 0.0179em),
    body-1: mat.define-typography-level(14px, 20px, 400, 'HalyardDisplayBook', 0.0179em),
    button: mat.define-typography-level(14px, 14px, 500, 'Brother1816', 0.0893em),
    caption: mat.define-typography-level(12px, 20px, 400, 'Brother1816', 0.0333em),
    input: mat.define-typography-level(inherit, 1.125, 400, 'Brother1816', 1.5px));

// Foreground Elements

// Light Theme Text
$dark-text: #5e5e5e;
$dark-primary-text: rgba($dark-text, 0.87);
$dark-accent-text: rgba($dark-primary-text, 0.54);
$dark-disabled-text: rgba($dark-primary-text, 0.38);
$dark-dividers: rgba($dark-primary-text, 0.12);
$dark-focused: rgba($dark-primary-text, 0.12);

$mat-light-theme-foreground: (base: black,
    divider: $dark-dividers,
    dividers: $dark-dividers,
    disabled: $dark-disabled-text,
    disabled-button: rgba($dark-text, 0.26),
    disabled-text: $dark-disabled-text,
    elevation: black,
    secondary-text: $dark-accent-text,
    hint-text: $dark-disabled-text,
    accent-text: $dark-accent-text,
    icon: $dark-accent-text,
    icons: $dark-accent-text,
    text: $dark-primary-text,
    slider-min: $dark-primary-text,
    slider-off: rgba($dark-text, 0.26),
    slider-off-active: $dark-disabled-text,
);

// Dark Theme text
$light-text: #5e5e5e;
$light-primary-text: $light-text;
$light-accent-text: rgba($light-primary-text, 0.7);
$light-disabled-text: rgba($light-primary-text, 0.5);
$light-dividers: rgba($light-primary-text, 0.12);
$light-focused: rgba($light-primary-text, 0.12);

$mat-dark-theme-foreground: (base: $light-text,
    divider: $light-dividers,
    dividers: $light-dividers,
    disabled: $light-disabled-text,
    disabled-button: rgba($light-text, 0.3),
    disabled-text: $light-disabled-text,
    elevation: black,
    hint-text: $light-disabled-text,
    secondary-text: $light-accent-text,
    accent-text: $light-accent-text,
    icon: $light-text,
    icons: $light-text,
    text: $light-text,
    slider-min: $light-text,
    slider-off: rgba($light-text, 0.3),
    slider-off-active: rgba($light-text, 0.3),
);

// Background config
// Light bg
$light-background: #e9e5e5;
$light-bg-darker-5: darken($light-background, 5%);
$light-bg-darker-10: darken($light-background, 10%);
$light-bg-darker-20: darken($light-background, 20%);
$light-bg-darker-30: darken($light-background, 30%);
$light-bg-lighter-5: lighten($light-background, 5%);
$dark-bg-tooltip: lighten(#bcc6c1, 20%);
$dark-bg-alpha-4: rgba(#bcc6c1, 0.04);
$dark-bg-alpha-12: rgba(#bcc6c1, 0.12);

$mat-light-theme-background: (background: $light-background,
    status-bar: $light-bg-darker-20,
    app-bar: $light-bg-darker-5,
    hover: $dark-bg-alpha-4,
    card: $light-bg-lighter-5,
    dialog: $light-bg-lighter-5,
    tooltip: $dark-bg-tooltip,
    disabled-button: $dark-bg-alpha-12,
    raised-button: $light-bg-lighter-5,
    focused-button: $dark-focused,
    selected-button: $light-bg-darker-20,
    selected-disabled-button: $light-bg-darker-30,
    disabled-button-toggle: $light-bg-darker-10,
    unselected-chip: $light-bg-darker-10,
    disabled-list-option: $light-bg-darker-10,
);

// Dark bg
$dark-background: #bcc6c1;
$dark-bg-lighter-5: lighten($dark-background, 5%);
$dark-bg-lighter-10: lighten($dark-background, 10%);
$dark-bg-lighter-20: lighten($dark-background, 20%);
$dark-bg-lighter-30: lighten($dark-background, 30%);
$light-bg-alpha-4: rgba(#e9e5e5, 0.04);
$light-bg-alpha-12: rgba(#e9e5e5, 0.12);

// Background palette for dark themes.
$mat-dark-theme-background: (background: $dark-background,
    status-bar: $dark-bg-lighter-20,
    app-bar: $dark-bg-lighter-5,
    hover: $light-bg-alpha-4,
    card: $dark-bg-lighter-5,
    dialog: $dark-bg-lighter-5,
    tooltip: $dark-bg-lighter-20,
    disabled-button: $light-bg-alpha-12,
    raised-button: $dark-bg-lighter-5,
    focused-button: $light-focused,
    selected-button: $dark-bg-lighter-20,
    selected-disabled-button: $dark-bg-lighter-30,
    disabled-button-toggle: $dark-bg-lighter-10,
    unselected-chip: $dark-bg-lighter-20,
    disabled-list-option: $dark-bg-lighter-10,
);

// Compute font config
@include mat.core($fontConfig);

// Theme Config

body {
    --primary-color: #95b4b5;
    --primary-lighter-color: #dfe9e9;
    --primary-darker-color: #789c9d;
    --text-primary-color: #{$dark-primary-text};
    --text-primary-lighter-color: #{$dark-primary-text};
    --text-primary-darker-color: #{$dark-primary-text};
}

$mat-primary: (main: #95b4b5,
    lighter: #dfe9e9,
    darker: #789c9d,
    200: #95b4b5, // For slide toggle,
    contrast : (main: $dark-primary-text,
        lighter: $dark-primary-text,
        darker: $dark-primary-text,
    ));
$theme-primary: mat.define-palette($mat-primary, main, lighter, darker);


body {
    --accent-color: #e9e4dc;
    --accent-lighter-color: #f8f7f5;
    --accent-darker-color: #dfd9ce;
    --text-accent-color: #{$dark-primary-text};
    --text-accent-lighter-color: #{$dark-primary-text};
    --text-accent-darker-color: #{$dark-primary-text};
}

$mat-accent: (main: #e9e4dc,
    lighter: #f8f7f5,
    darker: #dfd9ce,
    200: #e9e4dc, // For slide toggle,
    contrast : (main: $dark-primary-text,
        lighter: $dark-primary-text,
        darker: $dark-primary-text,
    ));
$theme-accent: mat.define-palette($mat-accent, main, lighter, darker);


body {
    --warn-color: #ff0000;
    --warn-lighter-color: #ffb3b3;
    --warn-darker-color: #ff0000;
    --text-warn-color: #{$light-primary-text};
    --text-warn-lighter-color: #{$dark-primary-text};
    --text-warn-darker-color: #{$light-primary-text};
}

$mat-warn: (main: #ff0000,
    lighter: #ffb3b3,
    darker: #ff0000,
    200: #ff0000, // For slide toggle,
    contrast : (main: $light-primary-text,
        lighter: $dark-primary-text,
        darker: $light-primary-text,
    ));
$theme-warn: mat.define-palette($mat-warn, main, lighter, darker);
;

$theme: (primary: $theme-primary,
    accent: $theme-accent,
    warn: $theme-warn,
    is-dark: false,
    foreground: $mat-light-theme-foreground,
    background: $mat-light-theme-background,
);
$altTheme: (primary: $theme-primary,
    accent: $theme-accent,
    warn: $theme-warn,
    is-dark: true,
    foreground: $mat-dark-theme-foreground,
    background: $mat-dark-theme-background,
);

// Theme Init
@include mat.all-component-themes($theme);

.theme-alternate {
    @include mat.all-component-themes($altTheme);
}

// Specific component overrides, pieces that are not in line with the general theming
button, [type=button], [type=reset], [type=submit] {
    background: none;
    display: flex;
    border: none;
    box-shadow: none;
    flex-direction: row;
    place-items: center;
    align-items: center;
    -webkit-appearance: none !important;
}


// Handle buttons appropriately, with respect to line-height
.mat-raised-button,
.mat-stroked-button,
.mat-flat-button {
    padding: 0 1.15em;
    margin: 0 .65em;
    min-width: 3em;
    line-height: 36.4px
}

.mat-standard-chip {
    padding: .5em .85em;
    min-height: 2.5em;
}

.material-icons {
    font-size: 24px;
    font-family: 'Material Icons Outlined', 'Material Icons';

    .mat-badge-content {
        font-family: 'Roboto';
    }
}

/* Importing Bootstrap SCSS file. */
@import '~bootstrap/scss/bootstrap';

html,
body {
    height: 100%;
    padding: 0px;
    margin: 0px;
    min-width: auto !important;
}

a {
    color: inherit;
    text-decoration: none;
    background-color: transparent;
}

a:hover {
    color: inherit;
    text-decoration: none;
}


// Authentication Login Form

.content-box {
    .login-form {
        width: 100%;
        max-width: 450px;
        display: flex;
        flex-direction: column;

        .remember-me {
            text-align: center;
        }

        .forgot-password {
            text-align: right;
            margin-top: 10px;
            font-size: 12px;
            font-family: 'Brother1816';
            text-transform: uppercase;
        }

        .action-button {
            place-content: center;
            display: flex;
            margin-top: 10px;
            margin-bottom: 20px;
        }

        .register-link {
            text-align: center;
            margin-top: 20px;
            margin-bottom: 40px;
            font-family: 'Brother1816';
            text-transform: uppercase;
            font-size: 12px;
        }
    }
}








// Helper Classes
.hide {
    display: none;
}


.margin-top-20 {
    margin-top: 20px;
}

.dflex {
    display: flex;
}

.margin-top-40 {
    margin-top: 40px;
}

.margin-top-80 {
    margin-top: 80px;
}

.padding-v-40 {
    padding: 40px 0px;
}

.max-width-80 {
    max-width: 80% !important;
}

.sticky {
    position: fixed;
    top: 0;
}

.place-top {
    place-content: flex-start !important;
}

.min-width-80 {
    min-width: 80% !important;
}

.content-center {
    align-items: center;
    place-content: center;
}

.flex-direction-column {
    flex-direction: column;
}
.flex-1 {
    flex: 1;
}


.section {
    // height: calc(100vh - 100px);
    min-height: 100vh;
    display: flex;
    flex-wrap: wrap;

    &.header-section {
        min-height: calc(100vh - 100px);
    }
}

.overflow {
    // min-height: calc(100vh - 100px);
    height: auto;

    .content {
        padding: 100px 0px;
        height: auto;
        flex: 1;
    }
}

.content {
    display: flex !important;
    flex-direction: column;
    height: auto;
    place-content: center;
    align-items: center;
    padding: 50px 0px;
    flex: 1;

    &.program-content {
        align-items: end;
    }

    &.stick-box {
        height: 100vh;
    }

    @media (max-width: 575px) {
        &.d-none {
            display: none !important;
        }
    }

    .context-box {
        padding: 40px;
        // min-width: 450px;
        max-width: 80%;
        z-index: 1;

        .meta {
            display: flex;
            flex-direction: row;
            padding: 20px 0px;
            flex-wrap: wrap;

            span {
                min-width: 150px;
                display: inline-flex;
            }
        }

        &.program-card {
            margin: 40px 0px;
            padding: 60px 80px;
            // max-width: 450px;
        }

        .head-icon {
            width: 40px;
            margin-bottom: 35px;
        }

        h1 {
            font-family: 'CormorantGaramond';
            font-weight: 800;
            font-size: 46px;
            line-height: 60px;
            color: #5E5E5E;
        }

        p,
        ul {
            font-family: 'HalyardDisplayBook';
            font-weight: 400;
            font-size: 18px;
            line-height: 24px;
            color: #5E5E5E;
        }

        ul {
            line-height: 36px;
        }

        .module-box {
            padding: 40px 0px;

            h1 {
                font-family: 'CormorantGaramond';
                font-weight: 800;
                font-size: 40px;
                line-height: 60px;
                color: #95B4B5;
            }

            p,
            ul {
                line-height: 40px;
            }
        }

        .contact-form {
            padding: 40px 0px;

            ul {
                list-style: none;
                padding: 0px;

                label {
                    font-size: 18px;
                    line-height: 24px;
                    width: 100%;
                }

                .form-control {
                    background-color: transparent;
                    border: none;
                    border-bottom: 1px solid #5E5E5E;
                    color: #5E5E5E;
                    border-radius: 0px;
                    padding-left: 0px;

                    &::placeholder {
                        /* Chrome, Firefox, Opera, Safari 10.1+ */
                        color: #5E5E5E;
                        opacity: 0.7;
                        /* Firefox */
                    }

                    &:focus {
                        outline: none;
                        box-shadow: none;
                    }
                }
            }
        }

        @media (max-width:767px) {
            max-width: 100%;
            padding: 20px;
        }
        @media(min-width: 768px) and (max-width:820px) {
            max-width: 100%;
            padding: 20px;
        }
    }
}


@media (max-width: 767px) {
    .sm-flex-swap {
        flex-direction: column-reverse;
    }

    .content {
        padding: 75px 0px;
        flex: auto;
    }
}

.landing-header {
    background: #BCC6C1;
    background-image: url('../assets/images/contemplative-girl.jpg');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}

.general-content {
    .context-box {
        h1 {
            font-family: 'Brother1816';
            font-size: 28px;
            font-weight: 400;
            line-height: 34px;
        }

        p,
        ul {
            font-family: 'HalyardDisplayBook';
            font-weight: 400;
            font-size: 18px;
            line-height: 24px;
            color: #5E5E5E;
        }
    }
}

.audio-box {
    display: flex;
    min-height: 200px;
    min-width: 200px;
    background-color: #eee;
    place-content: center;
    align-content: center;
}

.silence-space {
    background: #d3d9d6;
}

.problem-space {
    background: #E9E4DC;
    display: flex;
    position: absolute;
    width: 80%;
    height: 80%;
    // z-index: -1;
    right: 0px;
}

.solution-space {
    background: #F5F5F5;
    background-image: url('../assets/images/concentric-right.svg');
    background-position: top right;
    background-repeat: no-repeat;
}

.contemplation-space {
    background: #E9E4DC;
}

.beige-space {
    background: #E9E4DC;
}

.grey-space {
    background: #F5F5F5;
}

.white-space {
    background: #FFFFFF;
}

.beige-header-space {
    background: #E9E4DC;
    background-image: url('../assets/images/concentric-left.svg');
    background-position: top left;
    background-repeat: no-repeat;
}


.decision-space {
    background: #F5F5F5;
    background-image: url('../assets/images/concentric-right.svg');
    background-position: top right;
    background-repeat: no-repeat;

    .box {
        max-width: 450px;
        width: 90%;
        padding: 40px;

        h1 {
            font-family: 'CormorantGaramond';
            font-weight: 800;
            font-size: 40px;
            line-height: 60px;
            color: #95B4B5;
        }

        p,
        ul {
            font-family: 'HalyardDisplayBook';
            font-weight: 400;
            font-size: 18px;
            line-height: 24px;
            color: #5E5E5E;
        }
    }
}

.contemplation-negative-space {
    background: #F5F5F5;
    background-image: url('../assets/images/concentric-left.svg');
    background-position: top left;
    background-repeat: no-repeat;

    .box {
        max-width: 450px;
        width: 90%;
        padding: 40px;
    }

    h1 {
        font-family: 'CormorantGaramond';
        font-weight: 800;
        font-size: 40px;
        line-height: 60px;
        color: #95B4B5;
    }

    p,
    ul {
        font-family: 'HalyardDisplayBook';
        font-weight: 400;
        font-size: 18px;
        line-height: 24px;
        color: #5E5E5E;
    }
}

.align-top {
    place-content: flex-start;
}

#sticky-container {
    place-content: flex-start;
    position: relative;

    #stick-box {
        position: absolute;
    }
}

.video-container {
    overflow: hidden;
    position: relative;
    width: 80%;
    margin: 40px;

    &::after {
        padding-top: 56.25%;
        display: block;
        content: '';
    }

    iframe {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }
}



.batches-box {
    .modal-dialog {
        .modal-title {
            font-size: 18px;
            font-weight: 400;
            color: #5E5E5E;
        }

        font-family: 'Brother1816';

        .modal-content {
            border-radius: 25px;

            .chapter-container {
                width: 100%;

                .batch-card {
                    margin: 20px 0px;
                    border-radius: 25px !important;
                    background: #EDEDEB;
                    border-bottom: none !important;
                    padding: 20px;

                    h1 {
                        text-align: left;
                        font-size: 18px !important;
                        border: none;
                        padding: 0px;
                        text-transform: inherit;
                        font-weight: 400;
                        color: #5E5E5E;
                    }

                    p {
                        font-size: 15px !important;
                        font-family: 'Brother1816';
                        font-weight: 400;
                        color: #5E5E5E;
                    }

                    &:last-child {
                        border-bottom: none;
                    }
                }
            }
        }
    }
}



.content-section {
    min-height: auto;
}

.tab-container {
    display: flex;
    width: 100%;
    overflow: auto;
    place-content: center;
    ul {
        list-style: none;
        display: flex;
        background: #95b4b580;
        padding: 0px;
        border-radius: 30px;
        font-family: 'Brother1816';
        margin: 0px;
        li {
            color: #FFF;
            // text-transform:;
            padding: 20px 40px;
            cursor: pointer;
    
            white-space: nowrap;
            place-content: center;
            align-items: center;
            display: flex;
    
            &.active {
                color: #5E5E5E;
                background: #EDEDEB;
                border-radius: 30px;
            }

            @media (max-width:767px) {
                font-size: 14px;
                padding: 20px 25px;
            }
        }
    }
}

.list-box {
    display: flex;
    width: 100%;
    height: auto;
    padding: 100px;

    .box {
        display: flex;
        flex-direction: column;

        h1 {
            font-family: 'CormorantGaramond';
            font-weight: 800;
            font-size: 46px;
            line-height: 56px;
            color: #5E5E5E;
        }

        p {
            font-size: 15px !important;
            font-family: 'Brother1816';
            font-weight: 400;
            margin-top: 12px;
            color: #5E5E5E;
            opacity: 0.7;
        }

        .action-button {
            display: flex;
            place-self: flex-end;
        }
    }

    @media (max-width:767px) {
        padding: 20px;
    }

    @media(min-width: 768px) and (max-width:820px) {
        padding: 30px;
    }
}


.message-input-box {
    .mat-form-field {
        .mat-form-field-wrapper {
            padding-bottom: 0px !important;
        }
    }
}




.chapter-card {
    margin: 20px 0px !important;
    border-radius: 30px !important;
    min-height: 80px;
    background: #EDEDEB;
    font-family: 'Brother1816';
    border: 1px solid #70707021;
    padding: 20px 0px;

    .mat-expansion-panel-header {
        height: auto;
        min-height: 80px !important;
        align-items: start;

        .mat-content {
            place-content: space-between;
            align-items: center;
            flex-direction: column;

            .mat-expansion-panel-header-title {
                display: flex;
                flex-grow: 1;
                margin-right: 16px;
                font-family: 'Brother1816';
                text-transform: uppercase;
                color: #5E5E5E;
                font-size: 15px;
                margin: 16px;
                place-content: center;
                border-bottom: 1px solid #70707021;
                margin-left: 56px;
            }

            .mat-expansion-panel-header-description {
                flex-grow: 0 !important;
                margin-bottom: 16px;
                margin-right: 16px;
                margin-top: 16px;
                font-family: 'Brother1816';
                color: rgba(94, 94, 94, 0.54) !important;
                .mat-icon{
                    width: 32px;
                    height: 32px;
                }
            }
        }

        &.centered {
            .mat-content {
                .mat-expansion-panel-header-title {
                    margin-left: 16px !important;
                }

                .mat-expansion-panel-header-description {
                    margin-left: 16px !important;
                    width: 100%;
                }
            }
        }

        .mat-expansion-indicator {
            margin-right: 16px;
            margin-top: 16px;
            width: 24px;
            text-align: center;

            &::after {
                border-width: 0 1px 1px 0 !important;
                padding: 7px !important;
            }
        }
    }

    &.content-card{
        min-height: auto;
        .mat-expansion-panel-header {
            min-height: auto !important;
            &.locked {
                .mat-content {
                    flex-direction: row;
                    .mat-expansion-panel-header-title {
                        border-bottom: 0px;
                    }
                }
            }
        }
    }

    .mat-expansion-panel-content {
        .mat-expansion-panel-body {
            padding: 20px 0px 0px 0px;

            .module-list {
                padding-bottom: 16px;

                .module-name {
                    border-top: 1px solid #70707021;
                    border-bottom: 0.1px solid #70707021;
                    place-content: center;
                    font-family: 'Brother1816' !important;
                    text-transform: initial;
                    min-height: 60px !important;
                    height: auto;
                    opacity: 1;
                    padding: 16px 24px;
                    line-height: 24px;
                    color: #5E5E5E;
                }

                .content-card {
                    height: auto;
                    min-height: 48px;
                    padding: 16px 0px;
                    padding-bottom: 24px;

                    .content-title {
                        font-family: 'Brother1816';
                        opacity: 1;
                        white-space: normal;

                        &.link {
                            text-decoration: underline;
                            cursor: pointer;
                        }
                    }

                    .mat-list-item-content {
                        flex-direction: row-reverse !important;
                        padding-right: 16px;
                        opacity: 0.8;

                        .mat-icon {
                            margin: 0px 16px;
                            width: 32px;
                            height: 32px;
                            font-size: 32px;
                        }

                        .mat-list-text {
                            padding-right: 16px !important;
                        }
                    }
                }
            }
        }
    }
}




.modal-content,
.mat-dialog-container {
    border-radius: 25px;

    .box {
        position: relative;
        top: 0;
        opacity: 1;
        display: flex;
        align-items: left;
        place-content: flex-start;
        width: 100%;
        transform: scale(1);
        -webkit-transform: scale(1);
        -ms-transform: scale(1);
        z-index: 5;
        height: 90vh;
        overflow: hidden;
        flex-direction: column;
        border-radius: 25px;
        .back {
            display: inline-flex;
            place-content: center;
            align-items: center;
            font-size: 15px;
            color: #888;
            font-weight: 300;
            cursor: pointer;
            position: absolute;
            right: 1.5rem;
            top: 1.5rem;

            &:hover {
                color: $primary;
            }

            mat-icon {
                font-size: 20px;
                text-align: center;
                place-content: center;
                align-items: center;
                display: inline-flex;
            }
        }

        .title {
            // font-size: 20px;
            // text-align: left;
            // font-weight: 600;
            // padding-bottom: 20px;
            border-bottom: 1px solid #dee2e6;
            padding: 1.5rem;
            margin: 0px;
            padding-right: 3rem;
            background: rgba(192, 174, 160, 0.34) !important;
            color: rgb(149, 124, 105) !important;
            border-radius: 25px 25px 0px 0px;
            flex: 0;
            line-height: 32px;
            mat-icon {
                display: inline-block;
                position: relative;
                top: 4px;
                margin-left: 16px;
            }
            p {
                margin-top: 8px;
                font-size: 16px;
                color: #5E5E5E;
                line-height: 21px;
            }
        }

        .parameter-table {
            border-top: none;

            th {
                font-size: 12px;
                border-bottom: 1px solid rgba(0, 0, 0, 0.12);
                padding: 8px 24px;
                font-weight: bold;
                text-align: left;
                color: rgba(0, 0, 0, 0.54);
                cursor: pointer;
            }

            tr {
                &:hover {
                    background: #f5f5f5;
                }
            }

            td {
                color: rgba(0, 0, 0, 0.6);
                font-weight: 600;
                font-size: 14px;
                line-height: 16px;
                height: 48px;
                padding: 8px 24px;
                border-bottom: 1px solid rgba(0, 0, 0, 0.06);
                white-space: nowrap;
                vertical-align: middle;
                text-align: left;

                .with-icon {
                    display: inline-flex;
                    place-content: center;
                    align-items: center;

                    mat-icon {
                        margin-right: 1rem;
                    }
                }

                &.action {
                    text-align: center;
                    color: rgba(0, 0, 0, 0.54);
                }
            }
        }

        .content-section{
            overflow-y: auto;


            .action-button {
                flex: 0;
                padding: 0rem 2rem;
            }
        }
        .login-form {
            padding: 2rem;
            text-align: center;
            overflow-y: auto;
            flex: 1;
        }

        .action-button {
            flex: 0;
            padding: 0rem 2rem;
        }
    }
}


.mat-dialog-container {
    border-radius: 25px !important;

    .modal-container {
        margin: -1.5rem;

        .title {
            padding-right: 3.5rem;
        }
    }
}




// // Import Custom Variables
// @import "variables.scss";
// // Import Custom SCSS
// @import "global.scss";

// // Layout
// @import "layout/authentication.scss";
// @import "layout/dashboard-default.scss";
// @import "layout/dashboard-fixed.scss";
// @import "layout/error.scss";

// // Navigation
// @import "navigation/nav.scss";
// @import "navigation/topnav.scss";
// @import "navigation/sidenav/sidenav.scss";
// @import "navigation/sidenav/sidenav-dark.scss";
// @import "navigation/sidenav/sidenav-light.scss";

// html, body { height: 100%; }
// body { 
//     margin: 0; 
//     font-family: "brother", sans-serif;
//     font-size: 15px;
// }

// h1{
//     font-size: 22px !important;
// }

// .full-width {
//     width: 100%;
// }
// .half-width{
//     width: 50%;
// }
.link{
    cursor: pointer;
    text-decoration: none;
}
// a.disabled {
//     pointer-events: none;
//     cursor: not-allowed; 
// }
// a {
//     color: $primary;
//     transition: color 125ms ease-in-out;
//     text-decoration: none;
// }
// a:focus, a:hover {
//     color: $primary;
// }

// .btn{
//     border-radius: 5px;
//     -webkit-transition: all 200ms ease-in-out;
//     transition: all 200ms ease-in-out;
//     background-position: right bottom;
//     background-size: 200% 100%;
//     display: inline-block;
//     -webkit-user-select: none;
//     -moz-user-select: none;
//     -ms-user-select: none;
//     user-select: none;
//     font-weight: 400;
//     text-align: center;
//     vertical-align: middle;
//     padding: 12px 20px;
//     text-decoration: none;
//     min-width: 150px;
// }
// .btn.btn-primary{
//     background: -webkit-linear-gradient(right, $primary 50%, $primary 50%);
//     background: linear-gradient(to left, $primary 50%, $primary 50%);
//     color: #ffffff !important;
//     border: 2px solid transparent;
// }
// .btn.btn-primary, .btn-outline-primary:hover{
//     background: -webkit-linear-gradient(right, $primary 50%, $primary 50%);
//     background: linear-gradient(to left, $primary 50%, $primary 50%);
//     color: #ffffff !important;
//     border: 2px solid $primary;
// }
// .select-button.btn-outline-primary:hover{
//     background: #ffffff;
//     color: $primary !important;
//     border: 2px solid;
//     font-weight: 800;
// }
// .btn.btn-outline-primary{
//     color: $primary;
//     border-color: $primary;
// }

// .btn-primary:not(:disabled):not(.disabled).active, 
// .btn-primary:not(:disabled):not(.disabled):active, 
// .show>.btn-primary.dropdown-toggle {
//     color: #fff;
//     background-color: #1b8767;
//     border-color: #1b8767;
// }

// .mat-btn {
//     height: 48px;
//     min-width: 180px;
//     letter-spacing: 0.25px;
//     padding: 0px 16px;
//     font-size: 0.875rem;
//     font-weight: 700;
//     border-radius: 30px;
//     // &:hover {
//     //     background-color: rgba(255,255,255,.87)
//     // }
//     // &:focus {
//     //     background-color: rgba(255,255,255,.87)
//     // }
//     // &:active {
//     //     background-color: rgba(255,255,255,.87)
//     // }
// }

.popup-backdrop {
    background-color: #000000;
    z-index: 9998 !important;
}
.index-fix{
    z-index: 9999;
    
}
.ck.ck-balloon-panel{
    z-index: 9999 !important;
}

.avatar.avatar-ex-sm {
    max-height: 25px;
    filter: grayscale(1);
}
// .bg-half-170 {
//     padding: 170px 0;
// }
// .pricing-rates .title {
//     font-weight: 700;
//     font-size: 16px;
// }

// .classForContainer {
//     position: fixed;
//     right: -100px;
//     bottom: 30px;
//     transition: right .5s;
//     cursor: pointer;
//     background-color: rgba(47,85,212,.2);
//     padding: 5px;
//     align-items: center;
//     border-radius: 5px!important;
//     svg {
//         height: 16px;
//         width: 16px;
//         vertical-align: middle;
//         overflow: hidden;
//         margin-top: 2px;
//         margin-left: 5px;
//         fill: none;
//         stroke: #007bff!important;
//     }
// }
// .classForTransition {
//     right: 30px;
// }

.cdk-overlay-container{
    z-index: 10000 !important;
}
// .flex {
//     display: flex;
// }
// .flex-row {
//     display: flex;
//     flex-direction: row;
// }
// .flex-column {
//     display: flex;
//     flex-direction: column;
// }
// .flex-grow {
//     flex-grow: 1;
// }
// .flex-center {
//     align-items: center;
// }
// .flex-noshrink {
//     flex-shrink: 0;
// }
// .flex-nobasis{
//     flex-basis: 0;
// }
.flex-start {
    align-items: flex-start;
}
// .flex-end {
//     align-items: flex-end;
// }
// .justify-end {
//     justify-content: flex-end;
// }
// .justify-start {
//     justify-content: flex-start !important;
// }
.justify-center {
    justify-content: center !important;
}

.align-center{
    align-items: center;
}
.place-baseline {
    place-content: baseline;
}
.place-start {
    place-content: flex-start !important;
}
.place-end {
    place-content: flex-end !important;
}
.place-center {
    place-content: center;
}
.place-self-center {
    place-self: center !important;
}
.justify-between {
    justify-content: space-between !important;
}

// .flex-self-end {
//     align-self: flex-end;
// }
// .text-ellipsis {
//     overflow: hidden;
//     text-overflow: ellipsis;
//     white-space: nowrap;
// }
// .title-l {
//     font-size: 1.75rem;
//     font-weight: bold;
//     letter-spacing: -.04em;
// }
// .title-m {
//     font-size: 1.3125rem;
//     font-weight: 500;
//     letter-spacing: -.02em;
// }
// .title-xs {
//     font-size: .9375rem;
//     font-weight: 400;
// }
// .body-l {
//     font-size: .9375rem;
// }
// .fontSizeML {
//     font-size: 1rem;
// }
// .font-size-m {
//     font-size: .875rem !important;
// }
// .font-size-s {
//     font-size: .725rem !important;
// }
// .min-width-auto{
//     min-width: auto;
// }
// .font-weight-semibold {
//     font-weight: 600;
// }
// .text-underline { 
//     text-decoration: underline;
// }
// .fabric-icon {
//     align-self: center;
//     display: inline-flex;
//     -webkit-font-smoothing: antialiased;
// }
.cursor-pointer{
    cursor: pointer;
}

// .icon.disabled{
//     cursor: not-allowed;
//     color: #6c757d !important;
// }
// .navbar-brand {
//     font-family: 'kollektif';
//     letter-spacing: 2px;
//     font-size: 1.25rem;
//     align-items: center;
//     display: flex;
//     flex-direction: row;
// }
// .navbar-brand img {
//     height: 36px;
//     margin-right: 0.5rem;
// }

// // .badge {
// //     padding: 5px 8px;
// //     border-radius: 3px;
// //     letter-spacing: 0.5px;
// //     &.badge-light {
// //         color: $dark !important;
// //         background-color: $light !important;
// //     }

// //     &.badge-outline-light {
// //         color: $dark !important;
// //         border: 1px solid darken($light, 20%) !important;
// //         background-color: transparent !important;
// //     }
// //     &.badge-outline-danger {
// //         background-color: transparent!important;
// //         color: #e43f52!important;
// //         border: 1px solid #e43f52!important;
// //     }
// //     &.badge-outline-success {
// //         background-color: transparent!important;
// //         color: #28a745!important;
// //         border: 1px solid #28a745!important;
// //     }
// //     &.badge-pill {
// //         border-radius: 12px;
// //     }
// //     &:focus, &.focus {
// //         box-shadow: none !important;
// //     }
// // }


// .custom-tooltip {
//   background: #3c4858!important;
//   background-color: #dfeff3!important;
//     border: 1px solid #dfeff3!important;    
// //   color: #f8f9fc!important;
//   color: #3c4858!important;
//   font-size: 0.8rem;
//   font-weight: normal;
// }









// .scenario-table {
//     .mat-form-field-underline{
//         height: 0px !important;
//         display: none;
//     }
//     .mat-form-field-outline{
//         display: none !important;
//     }
//     .mat-form-field-infix{
//         border-top: 0.0em solid transparent;
//     }
//     .mat-form-field-appearance-fill .mat-form-field-flex{
//         background-color: transparent;
//     }
//     .mat-focused {
//         .mat-form-field-outline{
//             display: inherit !important;
//         }
//         .mat-form-field-flex{
//             background-color: rgb(36 177 135 / 3%) !important;
//             transform: scale3d(1, 1.0001, 1);
//             border: 1px solid $primary;
//             border-radius: 4px;
//         }
//     }
//     .mat-form-field-appearance-legacy .mat-form-field-wrapper{
//         padding-bottom: 0em;
//     }
//     .mat-form-field-appearance-legacy .mat-form-field-label {
//         padding: 0px 10px;
//     }
//     .mat-input-element {
//         padding: 0px 10px;
//     }
// }
// .modal-container {

//     .mat-form-field-underline {
//         height: 0px !important;
//         display: none;
//     }

//     .mat-form-field-outline {
//         display: none !important;
//     }

//     .mat-form-field-infix {
//         border-top: 0.0em solid transparent;
//         padding: 0.7375em 0;
//     }

//     .mat-form-field-appearance-fill .mat-form-field-flex {
//         background-color: transparent;
//     }

//     .mat-form-field-outline {
//         display: inherit !important;
//     }

//     .mat-form-field-flex {
//         background-color: rgb(36 177 135 / 3%) !important;
//         transform: scale3d(1, 1.0001, 1);
//         border: 1px solid $primary;
//         border-radius: 4px;
//     }

//     .mat-form-field-appearance-legacy .mat-form-field-wrapper {
//         padding-bottom: 0em;
//     }

//     .mat-form-field-appearance-legacy .mat-form-field-label {
//         top: 1.58125em;
//         padding: 0px 10px;
//     }

// }





// .mat-input-element {
//     padding: 0px 10px;
// }










// /* Auth Modules */

// .login-wrapper {
//     height: 100%;
//     flex-direction: row-reverse;
//     box-sizing: border-box;
//     display: flex;
//     place-content: center;
//     background-position: center;
//     background-size: cover;
//     background-repeat: no-repeat;
//     background-color: #fbfafa;
//     .content{
//       display: flex;
//       flex: 1;
//       align-items: center;
//       place-content: center;
//       .login-main{
//         background: #fff;
//         position: relative;
//         box-shadow: 0 4px 25px rgba(0,0,0,.1);
//         border-radius: 4px;
//         border: 1px solid #dcdcdc;
//         padding: 15px 20px 30px;
//         .login-box {
//           position: relative;
//           top: 0;
//           opacity: 1;
//           display: inline-block;
//           box-shadow: none;
//           align-items: center;
//           place-content: center;
//           padding: 60px 50px 40px 50px;
//           width: 100%;
//           background: #fff;
//           border-radius: 2px;
//           transform: scale(1);
//           -webkit-transform: scale(1);
//           -ms-transform: scale(1);
//           z-index: 5;
//           max-width: 500px;
//           margin-top: 30px;
//           .title {
//             text-align: center;
//             margin: 0 0 20px;
//             font-size: 24px;
//             line-height: 32px;
//             color: #1c1c1c;
//             font-weight: 600;
//             margin-bottom: 10px;
//           }
//           .subtitle{    
//             text-align: center;
//             margin-top: 35px;
//             margin-bottom: 35px;
//             font-size: 14px;
//             line-height: 20px;
//             font-weight: 400;
//           }
//           .action-button{
//             margin-top: 50px;
//             width: 100%;
//             height: 50px;
//             place-content: center;
//             display: flex;
//           }
//           .error-label{
//             font-size: 13px;
//             font-weight: 500;
//             margin: 0px 0px 30px 0px;
//             text-align: center;
//             span{
//               border-radius: 10px;
//               width: auto;
//               display: inline-block;
//               padding: 5px 20px;
//               margin-bottom: 0px;
//             }
//           }
//           .brand{
//             text-align: center;
//             margin-bottom: 30px;
//             img{
//               width: 175px;
//             }
//           }
//           .forgot-password{
//             font-size: 14px;
//             line-height: 20px;
//             color: $primary;
//             padding: 10px 0px;
//             display: inline-block;
//             font-weight: 600;
//           }
//           .remember-me {
//             justify-content: space-between;
//               width: 100%;
//               flex-direction: column;
//               display: flex;
//               place-content: center;
//             //   .forgot-password{
//             //     color: #20303c;
//             //     height: 20px;
//             //     line-height: 1;
//             //     font-size: 13px;
//             //     text-align: center;
//             //     text-decoration: underline;
//             //     margin-bottom: 30px;
//             //   }
//           }

//         }
//       }
//     }
//     .right-sidebar {
//       background-attachment: scroll;
//       background-size: 100% 100%;
//       flex: 0 0 420px;
//       height: 100%;
//       position: flex;
//       right: 0;
//       top: 0;
//       width: 420px;
//       z-index: 51;
//       display: flex;
//       align-items: center;
//       place-content: center;
//       img {
//         width: 90%;
//       }
//     }
//     @media (max-width: $breakpoint-tablet) {
//         .right-sidebar {
//             display: none;
//         }
//     }
// }


// /* FullScreen Forms */

// .content-wrapper {
//     flex-direction: column;
//     box-sizing: border-box;
//     display: flex;
//     align-items: flex-start;
//     .box{
//         position: relative;
//         top: 0;
//         opacity: 1;
//         display: inline-block;
//         align-items: left;
//         place-content: center;
//         width: 100%;
//         background: #fff;
//         border-radius: 2px;
//         transform: scale(1);
//         -webkit-transform: scale(1);
//         -ms-transform: scale(1);
//         z-index: 5;
//         min-height: 100%;

//         .back{
//             display: inline-flex;
//             place-content: center;
//             align-items: center;
//             font-size: 15px;
//             color: #888;
//             font-weight: 300;
//             cursor: pointer;
//             margin-bottom: 25px;
//             &:hover {
//                 color: $primary;
//             }
//             mat-icon {
//                 margin-right: 10px;
//                 font-size: 20px;
//                 text-align: center;
//                 place-content: center;
//                 align-items: center;
//                 display: inline-flex;
//             }
//         }
//         .login-form{
//             text-align: center;
//             .title{
//                 font-size: 45px;
//                 // letter-spacing: 1px;
//                 line-height: 1.5;
//                 font-weight: 600;
//             }
//             .input-container{
//                 width: 550px;
//                 min-width: 550px;
//                 display: inline-block;
//                 margin: 0 auto;
//                 p {
//                     font-size: 20px;
//                     line-height: 30px;
//                     font-weight: 400;
//                     margin-top: 3px;
//                     margin-bottom: 42px;
//                 }
//                 .input-control{
//                     font-size: 28px;
//                 }
//             }

//             .selector, .team-table{
//                 margin-top: 25px;
//                 margin-bottom: 50px;

//                 .select-container, .table-container{
//                     display: inline-block;    
//                     max-width: 700px;
//                     width: 100%;

//                     .select-button{
//                         border-radius: 100px;
//                     }

//                     .edit-table {
//                         max-height: 50vh;
//                         max-width: 100%;
//                         overflow-x: auto;
//                         overflow-y: auto;
//                         th {
//                             background: #fff;
//                             min-width: 200px;
//                             border: none;
//                             text-align: left;
//                             // padding: 0.25rem 6rem;
//                             margin-bottom: 1rem;
//                         }
//                         td {
//                             text-align: left;
//                             font-size: 18px;
//                             font-weight: 300;
//                             border: none;
//                             // padding: 0.25rem 6rem;
//                         }
//                     }

//                 }
//             }

//             .action-button {
//                 margin-top: 3rem !important;
//                 .next-button {
//                     font-size: 20px;
//                     height: 53px;
//                     padding-top: 8px;
//                     padding-bottom: 14px;
//                     color: #fff;
//                     background: #3899ec;
//                     line-height: 1.5;
//                     border-radius: 999px;
//                     box-sizing: border-box;
//                     white-space: nowrap;
//                     text-overflow: ellipsis;
//                     overflow: hidden;
//                     text-align: center;
//                     min-width: 168px;
//                     &:hover{
//                         background: #4eb7f5;
//                     }
//                     &[disabled]:hover{
//                         background: rgba(0,0,0,.12);
//                     }
//                 }
//             }
//         }
//     }
// }

// .left-background{
//     background-repeat: no-repeat;
//     background-position: right;
//     background-size: cover;
// }
// .padding-50{
//     padding: 50px 100px;
// }
// @media (max-width: $breakpoint-tablet) {
//     .padding-50{
//         padding: 25px;
//     }
//     .content-wrapper {
//         .box {
//             .login-form{
//                 .input-container {
//                     width: 550px;
//                     min-width: auto;
//                     max-width: 100%;
//                     display: inline-block;
//                     margin: 0 auto;
//                 }
//                 .title {
//                     font-size: 32px;
//                     line-height: 1.5;
//                     font-weight: 600;
//                 }
//             } 
//         }
//     }
// }

// .checklist-cards {
//     text-align: left;
//     list-style: none;
//     padding: 0px;
//     li {
//         font-size: 14px;
//         padding: 25px;
//         position: relative;
//         color: #525f7c;
//         p.counter{
//             font-size: 35px !important;
//             line-height: 36px !important;
//             margin-top: 3px !important;
//             font-weight: 600 !important;
//             color: #525f7c;
//             span{
//                 border-bottom: 3px solid #dfdfc7;
//             }
//         }
//         &:nth-child( even ) {
//             background: #f8faf9;
//             // p.counter{
//             //     span{
//             //         border-bottom: 4px solid #fff;
//             //     }
//             // }
//         }
//         &:nth-child( odd ) {
//             background: #e9f1f1;
//             // p.counter{
//             //     span{
//             //         border-bottom: 4px solid #1c1c1c;
//             //     }
//             // }
//         }
//         // &:before{
//         //     content: counter(my-awesome-counter);
//         //     position: absolute;
//         //     left: 0.5em;
//         //     color: $primary;
//         //     height: 3em;
//         //     __<<ngThemingMigrationEscapedComment2>>__
//         //     display: inline-block;
//         //     line-height: 2rem;
//         //     font-size: 3em;
//         //     text-align: center;
//         //     margin-right: 0.5rem;
//         //   }
//     }
// }

// .mat-ink-bar {
//     height: 4px !important;
// }
// .mat-tab-label-active{
//     opacity: 1 !important;
//     .mat-tab-label-content{
//         color: #1c1c1c !important;
//     }
// }
// .infix-100{ .mat-form-field-infix{
//     width: 70px !important;
// }
// }




// .scenario-card{
//     background-color: #fff;
//     border: 1px solid #dcdcdc!important;
//     border-radius: 4px;
//     box-shadow: 0 3px 6px rgba(34,37,37,.15);
//     color: #525257!important;
//     display: block;
//     margin-bottom: 20px;
//     padding: 20px!important;
//     position: relative;
//     text-align: left;
//     width: 100%;
//     transition: background-color .3s ease,box-shadow .3s ease,border-color .3s ease;
//     &:hover{
//         cursor: pointer;
//     }
//     .header{
//       .card-header-icon {
//         margin-bottom: 30px;
//         -o-object-fit: contain;
//         object-fit: contain;
//         -o-object-position: 0;
//         object-position: 0;
//         .icon.large {
//             width: 48px;
//             height: 48px;
//             line-height: 45px;
//             border-radius: 4px;
//             justify-content: center;
//             display: flex;
//             color: $primary;
//             border: 2px solid $primary;
//             font-size: 24px;
//             font-weight: 400;
//         }
//         small {
//             line-height: 14px;
//             padding: 0.25rem 0.50rem;
//             margin: 0rem 0rem 0rem 0.25rem;
//             margin-top: -2px;
//             margin-bottom: 2px;
//             display: inline-block;
//             font-size: 12px;
//         }
//       }
//       .card-title{
//         font-size: 21px;
//         line-height: 27px;
//         color: #1c1c1c;
//         font-weight: 600;
//         margin-bottom: 24px;
//         margin-top: 0;
//       }
//     }
//     .card-content-main{

//         .count-and-text {
//             margin: 0 32px 0 0px;
//             .text {
//                 color: rgba(0,0,0,.55);
//                 font-size: .75rem;
//                 font-weight: 600;
//             }
//             .title-xs{
//                 font-size: 16px;
//                 font-weight: 600;
//                 color: #2db288;
//             }
//         }
//             .alert{
//                 padding: 0.125rem 0.25rem;
//                 margin: 0rem 0rem 0rem 0rem;
//                 margin-top: -2px;
//                 margin-bottom: 2px;
//                 display: inline-block;
//                 font-size: 11px;
//             }

//         }
//     }
//     p{
//       margin: 16px 0 10px;
//       font-size: 15px;
//       line-height: 30px;
//       font-weight: 400;
//     }
//     .footer {
//       margin-top: auto;
//       padding-top: 20px;

//       .count-and-text {
//         margin: 0 32px 0 0px;
//         .text {
//             color: rgba(0,0,0,.55);
//             font-size: .75rem;
//             font-weight: 600;
//         }
//         .title-xs{
//             font-size: 16px;
//             font-weight: 600;
//             color: #2db288;
//         }
//     }
//       .link{
//         font-size: 16px;
//         font-weight: 600;
//         color: $primary;

//         &:after {
//           font-family: '>';
//           font-size: inherit;
//           font-style: normal;
//           font-variant: normal;
//           font-weight: 400;
//           line-height: 1;
//           text-transform: none;
//           transition: color .3s ease-in-out;
//           vertical-align: -1px;
//         }
//       }
//     }

//   .scenario-table{
//     tr{
//       &:hover{
//         background: #fbfafa;
//       }
//     }
//     th{
//       font-size: 16px;
//       line-height: 20px;
//       background-color: #fbfafa;
//       border-bottom: 1px solid #eaeaea;
//       color: #6c6c72;
//       padding: 20px 0 20px 16px;
//       vertical-align: middle;
//       text-align: left;
//     }
//     td{
//       padding: 20px!important;
//       font-size: 16px;
//       color: #525257;
//       text-align: left;
//     }
//     .strong{
//       font-weight: 600;
//       text-align: left;
//     }
// }

// .schedule-list{
//   .mat-expansion-panel-spacing{
//     margin: 0px !important;
//   }
//   .mat-expansion-panel-body {
//     padding: 0 0px 16px;
//   }
//   .mat-list-item-content{
//     padding: 0 24px !important;
//   }
//   .mat-list-item-disabled {
//     background-color: #fff;
//     color: rgba(0, 0, 0, 0.26);
//   }
//   .mat-list-item-content.mat-list-item-content-reverse {
//     flex-direction: row !important;
//     .mat-list-text{
//       padding-left: 0px !important;
//     }
//   }
// }
// .actions-tab{
//   .mat-form-field-appearance-legacy .mat-form-field-underline {
//     background-color: rgb(0 0 0 / 14%) !important;
//   }
// }


// /* Customize website's scrollbar like Mac OS
// Not supports in Firefox and IE */

// /* total width */
// .scrollbar::-webkit-scrollbar {
//     background-color:#fff;
//     width:16px
//   }

//   /* background of the scrollbar except button or resizer */
//   .scrollbar::-webkit-scrollbar-track {
//     background-color:#fff
//   }
//   .scrollbar::-webkit-scrollbar-track:hover {
//     background-color:#f4f4f4
//   }

//   /* scrollbar itself */
//   .scrollbar::-webkit-scrollbar-thumb {
//     background-color: rgba(0,0,0,0);
//     border-radius:16px;
//     border:5px solid #fff;
//   }

//   .scrollbar:hover::-webkit-scrollbar-thumb {
//   background-color: #a0a0a5;
//   }

//   .scrollbar::-webkit-scrollbar-thumb:hover {
//     background-color:#a0a0a5;
//     border:4px solid #f4f4f4
//   }

//   /* set button(top and bottom of the scrollbar) */
//   .scrollbar::-webkit-scrollbar-button {display:none}



//   .fixed-tab-size{
//     .mat-tab-label{
//         width: 200px;
//         .mat-tab-label-content{
//             display: inline-block;
//             justify-content: flex-start;
//             align-items: flex-start;
//             text-align: left;
//             text-overflow: ellipsis;
//             overflow: hidden;
//         }
//     }
//   }

//   .text-center{
//       text-align: center;
//   }

// .task-box {
//     .mat-list-item-content{
//         flex-direction: row-reverse !important;
//     }
// }
// .task-box {
//     cursor: move;
//   .mat-list-item-content{
//     flex-direction: row-reverse !important;
//     padding: 0px !important;
//     .mat-list-text{
//       padding-left: 0px !important;
//     }
//   }
// }

// .chip {
//     background: #eee;
//     padding: 5px 10px;
//     margin: 5px 10px;
//     display: inline-flex;
//     border-radius: 50px;
// }

// .default-cursor{
//     cursor: default !important;
// }



@media(max-width:820px) {
    .align-start-mobile {
        align-items: flex-start;
    }

    .max-width-80 {
        max-width: 100% !important;
    }
}


@media (min-width: 768px) and (max-width:820px) {
    .align-start-mobile {
        align-items: flex-start;
    }
    .content {
        padding: 75px 20px;
        flex: auto;
    }
}
.carousel-control-prev-icon{
    cursor: pointer;
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%235e5e5e' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath d='M5.25 0l-4 4 4 4 1.5-1.5L4.25 4l2.5-2.5L5.25 0z'/%3e%3c/svg%3e")
}
.carousel-control-next-icon{
    cursor: pointer;
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%235e5e5e' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath d='M2.75 0l-1.5 1.5L3.75 4l-2.5 2.5L2.75 8l4-4-4-4z'/%3e%3c/svg%3e")
}

.carousel-indicators li{
    cursor: pointer;
    background-color: #5e5e5e;
}
.visually-hidden {
    display: none;
}
.btn {
    padding: 1rem 3rem;
    /* color: #95b4b4; */
    background: #95b4b4;
    border-color: #95b4b4;
    color: #f5f5f5;
    font-family: 'BROTHER1816';
    text-transform: uppercase;

    &.btn-outline {
        background: #f5f5f5;
        border-color: #95b4b4;
        color: #95b4b4;
    }
}

.table {
    font-size: 16px;
    .link {
        text-decoration: underline;
    }
}